import { render, staticRenderFns } from "./Dispatch-length-time-modal.vue?vue&type=template&id=0fdf2dd8&scoped=true&"
import script from "./Dispatch-length-time-modal.vue?vue&type=script&lang=js&"
export * from "./Dispatch-length-time-modal.vue?vue&type=script&lang=js&"
import style0 from "./Dispatch-length-time-modal.vue?vue&type=style&index=0&id=0fdf2dd8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fdf2dd8",
  null
  
)

export default component.exports