import { render, staticRenderFns } from "./TicketOrderMobile.vue?vue&type=template&id=122cc53a&scoped=true&"
import script from "./TicketOrderMobile.vue?vue&type=script&lang=js&"
export * from "./TicketOrderMobile.vue?vue&type=script&lang=js&"
import style0 from "./TicketOrderMobile.vue?vue&type=style&index=0&id=122cc53a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "122cc53a",
  null
  
)

export default component.exports